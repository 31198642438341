<div>
    <div>
        <h2 class="matHeadTitle">Hinweis</h2>
    </div>
    <br />
    <div class="matContent">{{data?.data?.title}}</div>
    <div>
        <div>
            <div class="row">
                <div class="col-md-8"> Verknüpfte Lager: </div>
                <div class="col-md-2 rfloat">{{data.data[0].count}}</div>
                <div class="col-md-1 rfloat">
                    <span class=" cursor-pointer" (click)="show = !show">
                  {{show ? 'Weniger' : 'Mehr'}}
              </span>
                </div>
            </div>
            <div *ngIf="show">
                <div class="row" *ngFor="let storages of data.data[0].storages">

                    {{storages.cityName || storages.storageName | titlecase }}</div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions style="justify-content: space-between;">
        <button mat-button [mat-dialog-close]="false" class="btn btn-default WH-btnWidth">Abbrechen</button>
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="btn btn-primary WH-btnWidth">Löschen</button>
    </div>
</div>



<!-- <div *ngIf="data?.code=='clients'">
    <div>
      <h2 class="matHeadTitle">Subscribed Clients</h2>
    </div>
    <br />
    <div class="matContent" *ngIf="shotlist">
      <div *ngIf="data?.info.length==1">
        This storage has subscribed by
        {{data?.info[0].usersSettings.firstName +' '+data?.info[0].usersSettings.lastName}}
      </div>
      <div *ngIf="data?.info.length==2">
        This storage has subscribed by
        {{data?.info[0].usersSettings.firstName +' '+data?.info[0].usersSettings.lastName}} and
        {{data?.info[1].usersSettings.firstName +' '+data?.info[1].usersSettings.lastName}}
      </div>
      <div *ngIf="data?.info.length>2">
        This storage has subscribed by
        {{data?.info[0].usersSettings.firstName +' '+data?.info[0].usersSettings.lastName}},
        {{data?.info[1].usersSettings.firstName +' '+data?.info[1].usersSettings.lastName}}
         and {{data?.info.length - 2 !=0 ?data?.info.length - 2:'' }} others
      </div>
         <span (click)="shotlist=!shotlist" style="color: #e30613;cursor: pointer;">see more...</span> 
        </div>
        <div *ngIf="!shotlist">
            <div *ngFor="let user of data?.info" style="display: flex;">
                <span style="width: 100%;">{{user.usersSettings.firstName +' '+user.usersSettings.lastName}}</span>
                <span class="material-icons WH-visibiltyIcon cursor-pointer" slot="end" style="display: contents;" [mat-dialog-close]="user.companyDetails">visibility</span>
            </div>
            <span (click)="shotlist=!shotlist" style="color: #e30613;cursor: pointer;">see less...</span>
        </div>
  <div mat-dialog-actions  style="justify-content:center">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="btn btn-primary WH-btnWidth" >Löschen</button>
  </div>
  </div>





  <div *ngIf="data?.code=='storage'">
    <div>
      <h2 class="matHeadTitle">Subscribed Storages</h2>
    </div>
    <br />
    <div class="matContent" *ngIf="shotlist">
      <div *ngIf="data?.info.length==1">
        This price region is subscribed by storages 
        {{data?.info[0].cityName}}
      </div>
      <div *ngIf="data?.info.length==2">
        This storage has subscribed by storages
        {{data?.info[0].cityName}} and
        {{data?.info[1].cityName}}
      </div>
      <div *ngIf="data?.info.length>2">
        This storage has subscribed by
        {{data?.info[0].cityName}},
        {{data?.info[1].cityName}}
         and {{data?.info.length - 2 !=0 ?data?.info.length - 2:'' }} other storages
      </div>
         <span (click)="shotlist=!shotlist" style="color: #e30613;cursor: pointer;">see more...</span> 
        </div>
        <div *ngIf="!shotlist">
            <div *ngFor="let user of data?.info" style="display: flex;">
                <span style="width: 100%;">{{user.cityName}}</span>
                <span class="material-icons WH-visibiltyIcon cursor-pointer" slot="end" style="display: contents;" [mat-dialog-close]="user._id">visibility</span>
            </div>
            <span (click)="shotlist=!shotlist" style="color: #e30613;cursor: pointer;">see less...</span>
        </div>
  <div mat-dialog-actions  style="justify-content:center">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="btn btn-primary WH-btnWidth" >Löschen</button>
  </div>
  </div>



  <div *ngIf="data?.code=='product'">
    <div>
      <h2 class="matHeadTitle">Cannot delete product</h2>
    </div>
    <br />
    <div class="matContent" *ngIf="shotlist">
      <div *ngIf="data?.info.length==1">
        This Product Qualities are subscribed by storages 
        {{data?.info[0]._id}}
      </div>
      <div *ngIf="data?.info.length==2">
        This Product Qualities are subscribed by storages
        {{data?.info[0]._id}} and
        {{data?.info[1]._id}}
      </div>
      <div *ngIf="data?.info.length>2">
        This Product Qualities are subscribed by
        {{data?.info[0]._id}},
        {{data?.info[1]._id}}
         and {{data?.info.length - 2 !=0 ?data?.info.length - 2:'' }} other storages
      </div>
         <span (click)="shotlist=!shotlist" style="color: #e30613;cursor: pointer;">see more...</span> 
        </div>
        <div *ngIf="!shotlist">
            <div *ngFor="let user of data?.info" style="display: flex;">
                <span style="width: 100%;">{{user._id}}</span>
                <span class="material-icons WH-visibiltyIcon cursor-pointer" slot="end" style="display: contents;" [mat-dialog-close]="user.storageId">visibility</span>
            </div>
            <span (click)="shotlist=!shotlist" style="color: #e30613;cursor: pointer;">see less...</span>
        </div>
  <div mat-dialog-actions  style="justify-content:center">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="btn btn-primary WH-btnWidth" >Löschen</button>
  </div>
  </div>




  <div *ngIf="data?.code=='quality'">
    <div>
      <h2 class="matHeadTitle">Cannot delete quality</h2>
    </div>
    <br />
    <div class="matContent" *ngIf="shotlist">
      <div *ngIf="data?.info.length==1">
        This quality is subscribed by storages 
        {{data?.info[0]._id}}
      </div>
      <div *ngIf="data?.info.length==2">
        This quality is subscribed by storages
        {{data?.info[0]._id}} and
        {{data?.info[1]._id}}
      </div>
      <div *ngIf="data?.info.length>2">
        This quality is subscribed by
        {{data?.info[0]._id}},
        {{data?.info[1]._id}}
         and {{data?.info.length - 2 !=0 ?data?.info.length - 2:'' }} other storages
      </div>
         <span (click)="shotlist=!shotlist" style="color: #e30613;cursor: pointer;">see more...</span> 
        </div>
        <div *ngIf="!shotlist">
            <div *ngFor="let user of data?.info" style="display: flex;">
                <span style="width: 100%;">{{user._id}}</span>
                <span class="material-icons WH-visibiltyIcon cursor-pointer" slot="end" style="display: contents;" [mat-dialog-close]="user.storageId">visibility</span>
            </div>
            <span (click)="shotlist=!shotlist" style="color: #e30613;cursor: pointer;">see less...</span>
        </div>
  <div mat-dialog-actions  style="justify-content:center">
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="btn btn-primary WH-btnWidth" >Löschen</button>
  </div>
  </div> -->