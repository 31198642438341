import { config } from './../constant/config';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '../env.service';

@Injectable({
  providedIn: 'root'
})
export class CMSService {
  freshdata: any = [];
  apiUrl:any;
  constructor(private http: HttpClient,private env: EnvService) {
    this.apiUrl=this.env.apiUrl;
   }
  insertNewEmployee(data): Observable<any> {
    return this.http.post(this.apiUrl + "cms/insertEmployee", data);
  };
  newListAfterInsert(data) {
    this.freshdata = data;
  }
  getEmployees(data): Observable<any> {
    return this.http.post(this.apiUrl + "cms/getEmployees", data);
  }
  deleteEmployee(val): Observable<any> {
    return this.http.post(this.apiUrl + "cms/deleteEmployee", val);
  }
  editEmployee(data): Observable<any> {
    return this.http.post(this.apiUrl + "cms/editEmployee", data);
  };
  getorderpdf(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cms/getorderpdf', data)
  };

  getAllRegions(): Observable<any> {
    return this.http.get(this.apiUrl + "cmsStorages/getRegions");
  };

  addNewStorage(data): Observable<any> {
    return this.http.post(this.apiUrl + "cmsStorages/addNewStorage", data);
  };

  forgotPassword(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cms/forgotPassword', data);
  }
  editCmsUserBasicInfo(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cms/personalInfoEdit', data);
  }

  getOrdersData(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cms/getOrders', data);
  }
}
