import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unitsReplace'
})
export class UnitsReplacePipe implements PipeTransform {
  transform(value: any): any {
    if(value === "liter"){
      return "L"
    }else if(value === "kg"){
      return "Kg"
    }else{
      return "Pound"
    }
  }

}
