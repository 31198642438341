import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'getGridfs'
})
export class GetGridfsPipe implements PipeTransform {
  constructor(private auth: AuthService) { }
  async transform(fileId: string) {

    return new Promise(async (resolve, reject) => {
      try {
         await this.auth.getGridFsData(fileId).subscribe(val=>{
         if(val.success){
           let data = val.data;
           if(data.mimetype == "application/pdf"){
             let dat = `data:${data.mimetype};base64,${val.data.data}`
            resolve(dat)
           }else{
            resolve(`data:${data.mimetype};base64,${val.data.data}`)
           }
      
         }
        });
        // if (!logoFile.success) {
        //   return
        // }
        // this.auth.pictureName = logoFile.data.name;
        // return `data:image/jpg;base64,${logoFile.data.data}`;
        // resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

}
