import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueReplacer'
})
export class valueReplacerPipe implements PipeTransform {

  transform(value: any, decimal: boolean, negative: boolean) {
    if (negative){
      value = parseInt(value.toString().replace(/-/g, ''));
    }    
    var regex = new RegExp("^[0-9,.]|-");
    if (value && regex.test(value)) {
      if(decimal){
        value = value.toLocaleString("de-DE", { minimumFractionDigits: 2 });
        return value;
      }else{
        value = value.toLocaleString("de-DE", { minimumFractionDigits: 0 });
        return value;
      }
    } else {
      return 0
    }
  }

}
