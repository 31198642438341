import { validation } from './../constant/validations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CMSService } from 'src/app/services/cms.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormValidateService } from '../services/form-validate.service';
import { SnackBarService } from '../services/snack-bar.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassForm: FormGroup;
  forgetPassword: boolean = true;
  serverError: boolean = false;
  fullscreen: boolean;
  backEndMsg: string;
  errMsg;
  constructor(public cmsService: CMSService,
    private fb: FormBuilder,
    private formValidate: FormValidateService,
    private snackBar: SnackBarService,
    private router: Router) { }

  ngOnInit(): void {
    this.errMsg=this.formValidate.errMessage()
    this.forgotPassForm = this.fb.group({
      emailId: ['', [Validators.required, Validators.pattern(validation.email)]],
    })
  }
  forgotPassword() {
    if (this.forgotPassForm.valid) {
      this.fullscreen = true;
      this.cmsService.forgotPassword(this.forgotPassForm.value).subscribe(data => {
        if (data.success) {
          this.fullscreen = false;
          if(data.msg == "User not found with this email.Please register and we will contact you." || data.msg == "Email sent failed"){
            this.serverError = true;
            this.backEndMsg = data.msg;
          }else{
            this.serverError = false;
            this.backEndMsg = '';
            this.forgetPassword = false;
          }
        }
      },
      error => {
        this.fullscreen = false;
        this.snackBar.showSnackBar('Something went wrong', 'Dismiss', 3000, 'blue-snackbar')
        console.log(error.msg)
      });
    }
    else {
      this.formValidate.validateAllFormFields(this.forgotPassForm)
    }
  }
}
