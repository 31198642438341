import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  @Input() fullscreen: boolean;
  @Input() loaderStyle: string;

  constructor() {}


  ngOnInit(): void {
    this.loaderStyle = this.loaderStyle == 'graph'? 'graph': 'spinner';
  }

  close(){
    this.fullscreen = false;
  }

}
