<div class="WH-loginpage-middleContent">
    <div class="row align-items-center justify-content-center h-100">
        <div class="col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4">
            <form [formGroup]="registerForm" (ngSubmit)="registerSubmit()">
                <div class="WH-loginForm WH-login">
                    <h5 class="WH-loginTxt">Register Your Account</h5>
                    <div class="form-group">
                        <label for="company">Company</label>
                        <input type="text" class="form-control rounded-0" formControlName="company"
                            autocomplete="new-password">
                        <!-- error -->
                        <div *ngIf="registerForm?.get('company')?.invalid && (registerForm?.get('company')?.dirty || registerForm?.get('company')?.touched)"
                            class="alert alert-danger">
                            <div *ngIf="registerForm?.get('company')?.errors?.required">
                                Company name is required.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="salutation" class="WH-margin">Salutation</label>
                        <select class="form-control rounded-0 WH-margin" formControlName="salutation">
                            <option value="" style="text-align: center;">Please Select here</option>
                            <option value="Mr">Mr.</option>
                            <option value="Mrs">Mrs.</option>
                        </select>
                        <div *ngIf="registerForm?.get('salutation')?.invalid && (registerForm?.get('salutation')?.dirty || registerForm?.get('salutation').touched)"
                            class="alert alert-danger">
                            <div *ngIf="registerForm?.get('salutation')?.errors?.required">
                                Salutation is required.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <div class="WH-pr-5">
                                    <label for="firstname">First name</label>
                                    <input type="text" class="form-control rounded-0 WH-spacing-right WH-mb-xs"
                                        formControlName="firstName" autocomplete="new-password">
                                    <!-- error -->
                                    <div *ngIf="registerForm?.get('firstName')?.invalid && (registerForm?.get('firstName')?.dirty || registerForm?.get('firstName')?.touched)"
                                        class="alert alert-danger WH-alertForm-left">
                                        <div *ngIf="registerForm?.get('firstName')?.errors?.required">
                                            First name is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                <div class="WH-pl-5">
                                    <label for="salutation">Last name</label>
                                    <input type="text" class="form-control rounded-0 WH-spacing-left "
                                        formControlName="lastName" autocomplete="new-password">
                                    <!-- error -->
                                    <div *ngIf="registerForm?.get('lastName')?.invalid && (registerForm?.get('lastName')?.dirty || registerForm?.get('lastName')?.touched)"
                                        class="alert alert-danger WH-alertForm-right">
                                        <div *ngIf="registerForm?.get('lastName')?.errors?.required">
                                            Last name is required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="telephone">Telephone:</label>
                        <input type="number" class="form-control rounded-0" formControlName="telephone"
                            autocomplete="new-password">
                        <!-- error -->
                        <div *ngIf="registerForm?.get('telephone')?.invalid && (registerForm?.get('telephone')?.dirty || registerForm?.get('telephone')?.touched)"
                            class="alert alert-danger">
                            <div *ngIf="registerForm?.get('telephone')?.errors?.required">
                                Telephone is required.
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email">Email</label>
                        <input type="email" class="form-control rounded-0 WH-margin" formControlName="email" autocomplete="new-password" >
                        <!-- error -->
                        <div *ngIf="registerForm?.get('email')?.invalid && (registerForm?.get('email')?.dirty || registerForm?.get('email')?.touched)"
                            class="alert alert-danger">
                            <div *ngIf="registerForm?.get('email')?.errors?.required">
                                Email is required.
                            </div>
                            <div *ngIf="registerForm?.get('email')?.errors?.pattern">Email must be a
                                valid
                                email address
                            </div>
                        </div>
                    </div>

                    <!-- <button type="submit" class="btn btn-primary w-100 border-0"
                        [disabled]="registerForm.invalid">Register</button> -->

                        <button type="submit" class="btn btn-primary w-100 border-0">Register</button>
                </div>
            </form>
        </div>
    </div>
    <app-loader [fullscreen]="fullscreen"></app-loader>
</div>