import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { config } from '../constant/config';
import { EnvService } from '../env.service';
import { Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
import { LoaderService } from './loader.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
pictureName = '';
  protected authenticationState = new BehaviorSubject(false);
  authenticationState$ = this.authenticationState.asObservable().pipe(distinctUntilChanged());
  userInfo: any = {
    firstName: localStorage.getItem('firstName'),
    lastName: localStorage.getItem('lastName'),
    salutation: localStorage.getItem('salutation'),
    phNumber: localStorage.getItem('telephone'),
    email: localStorage.getItem('email'),
  };
  userdetails: any = new BehaviorSubject(this.userInfo);
  apiUrl:any;
  private profileObs$: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor(
    private HttpClient: HttpClient, 
    private router: Router,
    private env: EnvService,
    private loader:LoaderService) {

    this.apiUrl = this.env.apiUrl;

   }
  isLoginSubject = new BehaviorSubject<boolean>(this.checkToken());

  registration(data): Observable<any> {
    return this.HttpClient.post(this.apiUrl + 'cms/registration', data);
  }
  loginUser(loginData): Observable<any> {
    return this.HttpClient.post(this.apiUrl + "cms/loginCms", loginData);
  }
  // checkToken() {
  //   return !!localStorage.getItem('token')
  // }
  checkToken() {
    if (localStorage.getItem('token')) {
      this.authenticationState.next(true);
      return this.authenticationState.value;
    } else {
      this.authenticationState.next(false);
      return this.authenticationState.value;
    }
  }

  setTokenlocalStorage(tokenData: tokenInfo) {
    this.isLoginSubject.next(true);
    this.userInfo = {
      firstName: tokenData.data[0].firstName,
      lastName: tokenData.data[0].lastName,
      salutation: tokenData.data[0].salutation,
      phNumber: tokenData.data[0]['telephone'],
      email: tokenData.data[0].email,
    }
    this.userdetails.next(this.userInfo);
    localStorage.setItem('token', tokenData.token);
    localStorage.setItem('tempPassword', tokenData.data['0']['tempPassword']),
    localStorage.setItem('uId', tokenData.data[0].uId);
    localStorage.setItem('email', tokenData.data[0].email);
    localStorage.setItem('role', tokenData.data[0].role);
    localStorage.setItem("firstName", tokenData.data[0].firstName);
    localStorage.setItem('lastName', tokenData.data['0']['lastName']);
    localStorage.setItem('salutation', tokenData.data['0']['salutation']);
    localStorage.setItem("profilePicId", tokenData.data[0]['profilePicId']);
    localStorage.setItem("telephone", tokenData.data[0]['telephone']);
    localStorage.setItem("verification", tokenData.data[0].verification);
    localStorage.setItem("lastActiveTime", tokenData.data[0].lastActiveTime);
    localStorage.setItem("userId", tokenData.data[0].userId);
    this.setProfileObs(tokenData.data[0]['profilePicId']);
  }

  clearLocalStorage() {
    // localStorage.removeItem("token");
    // localStorage.removeItem("tempPassword");
    // localStorage.removeItem("uId");
    // localStorage.removeItem("email");
    // localStorage.removeItem("role");
    // localStorage.removeItem("firstName");
    // localStorage.removeItem("lastName");
    // localStorage.removeItem("salutation");
    // localStorage.removeItem("lastActiveTime");
    // localStorage.removeItem("telephone");
    // localStorage.removeItem("verification");
    // localStorage.removeItem("userId");
    // localStorage.removeItem("profilePicId");
    localStorage.clear();
  }
  logout() {
    this.loader.hide();
    this.isLoginSubject.next(false);
    this.clearLocalStorage();
    this.router.navigate(['login']);
  }
  getToken() {
    return localStorage.getItem('token')
  }
  isLoggedIn() {
    return this.authenticationState.value;
  }
  updatePassword(data): Observable<any> {
    return this.HttpClient.post(this.apiUrl + "cms/updateNewPassword", data)
  }
  uploadProfilePic(data): Observable<any> {
    return this.HttpClient.post(this.apiUrl + 'cms/uploadProfileImage', data);
  }
  uploadPdf(data): Observable<any> {
    return this.HttpClient.post(this.apiUrl + 'cms/uploadlegaldoc', data);
  }
  deleteProfilePic(data): Observable<any> {
    return this.HttpClient.post(this.apiUrl + 'cms/deleteProfilePic', data);
  }
  deleteLegalDoc(data): Observable<any> {
    return this.HttpClient.post(this.apiUrl + 'cms/deleteLegalDoc', data);
  }
  getGridFsData(fileId): Observable<any> {
    return this.HttpClient.post(`${this.apiUrl}/cms/getGridFsData`, { fileId });
  }
  getProfileObs(): Observable<any> {
    return this.profileObs$.asObservable();
  }
  setProfileObs(profile: any) {
    this.profileObs$.next(profile);
  }
}
export interface tokenInfo {
  success: boolean,
  data: any[],
  codeNode: number,
  token: any,
  msg: any,
}