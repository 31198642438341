import { Directive, Input, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[OnlyNumberWithDot]'
})
export class OnlyNumberWithoutDotComma {
  private regex: RegExp = new RegExp(/^[0-9.-]*\,?\d{0,2}$/);
  constructor(private elRef: ElementRef) { }
  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this.elRef.nativeElement.value;
    this.elRef.nativeElement.value = initalValue.replace(/[^\d.]/g, '');
    this.elRef.nativeElement.value = initalValue.toString().replace(/-/g, '');
    if ( initalValue !== this.elRef.nativeElement.value) {
      event.stopPropagation();
    }else{
      if(!this.elRef.nativeElement.value.match(this.regex)){
        this.elRef.nativeElement.value =  this.elRef.nativeElement.value.substring(0, this.elRef.nativeElement.value.length - 1)
      }
    }
  }
}