<div class="WH-loginpage-middleContent">
    <div class="row align-items-center justify-content-center h-100">
        <div class="col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4">
            <form [formGroup]="loginForm" (ngSubmit)="loginSubmit()">
                <div class="WH-loginForm WH-login">
                    <h5 class="WH-loginTxt">Login Your Account</h5>
                    <div class="form-group">
                        <label for="email">Email*</label>
                        <input type="email" class="form-control rounded-0 h-40" formControlName="email">
                        <!-- error -->
                        <div *ngIf="loginForm?.get('email')?.invalid && (loginForm?.get('email')?.dirty || loginForm?.get('email')?.touched)" class="alert alert-danger">
                            <div *ngIf="loginForm?.get('email')?.errors?.required">
                                {{errMessage}}
                            </div>
                            <div *ngIf="loginForm?.get('email')?.errors?.pattern">Email must be a valid email address
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="password">Password*</label>
                        <div class="input-group">
                            <input [type]="passwordVisibility ? 'text' : 'password'" class="form-control rounded-0 h-40" formControlName="password">
                            <div class="input-group-append vertical">
                                <span class="input-group-text material-icons WH-visibiltyIcon cursor-pointer" (click)="passwordVisibility = !passwordVisibility">
                                    {{passwordVisibility ? 'visibility' : 'visibility_off'}}
                                </span>
                            </div>
                        </div>

                        <!-- error -->
                        <div *ngIf="loginForm?.get('password')?.invalid && (loginForm?.get('password')?.dirty || loginForm?.get('password')?.touched)" class="alert alert-danger">
                            <div *ngIf="loginForm?.get('password')?.errors?.required">
                                {{errMessage}}
                            </div>
                        </div>
                    </div>
                    <div class="WH-forgotPassword mb-15 text-right">
                        <a href="#" class="" routerLink="/forgot">Forgot password?</a>
                    </div>
                    <button type="submit" class="btn btn-primary w-100 border-0">LOGIN</button>

                    <!-- <div class="WH-loginError d-none" *ngIf="true">
                        <div class="WH-newUser">
                            <div class="text-center WH-newUser-txt"><span>New User?</span> Create an account.
                            </div>
                        </div>
                        <div class="WH-errorMsgBox">
                            <ul class="WH-errorMsg">
                                <li>This user is already logged in on another <br> device or browser.</li>
                                <li>If you log in you will be logged out from all other <br> devices or browser.
                                </li>
                            </ul>
                        </div>
                    </div> -->
                    <!-- when user not found error-->
                    <div class="WH-loginError mt-3" *ngIf="userNotFound">
                        <div class="WH-errorMsgBox">
                            <ul class="WH-errorMsg">
                                <li>{{backendErrorMsg}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <app-loader [fullscreen]="fullscreen"></app-loader>
</div>