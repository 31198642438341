import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { EnvServiceProvider } from './env.service.provider';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HeaderComponent } from './header/header.component';
import { AppRoutingModule } from './app-routing.module';
import { RegisterComponent } from './register/register.component';
import { CommomModuleModule } from "./common-module/common-module.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './services/auth.guard';
import { TokenInterceptorService } from "./services/token-interceptor.service";
import { AuthService } from "./services/auth.service";
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { TitleCasePipe } from '@angular/common';
import { CmsUseSettingsComponent } from './cms-use-settings/cms-use-settings.component';
import { ShowSubscribedClientsComponent } from './show-subscribed-clients/show-subscribed-clients.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { GetGridfsPipe } from './pipes/get-gridfs.pipe';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { MaterialModule } from "./material.module";
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { config } from "../app/constant/config";
import {OverlayModule} from '@angular/cdk/overlay';
import { FormsModule } from '@angular/forms';
import { TextareaComponent } from './textarea/textarea.component';
import { ClientLimitComponent } from './client-limit/client-limit.component';


const socket: SocketIoConfig = {
  url: config.socket, 
  options: {
   query:{uId:localStorage.getItem('uId'),from:'cms'},
   autoConnect: true,
   reconnection: true,
   reconnectionDelay: 1000,
   reconnectionDelayMax : 5000,
   reconnectionAttempts: 10,
   transports: ['websocket']
  } };
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    HeaderComponent,
    RegisterComponent,
    DeleteConfirmationComponent,
    CmsUseSettingsComponent,
    ShowSubscribedClientsComponent,
    ImageUploadComponent,
    GetGridfsPipe,
    TextareaComponent,
    ClientLimitComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CommomModuleModule,
    HttpClientModule,
    ImageCropperModule,
    MaterialModule,
    OverlayModule,
    SocketIoModule.forRoot(socket),
    FormsModule
    
  ],
  entryComponents: [
    CmsUseSettingsComponent,
    ImageUploadComponent
   ],

  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [
    EnvServiceProvider,
    AuthGuard,
    TitleCasePipe,
    AuthService,
    GetGridfsPipe,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
