import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { validation } from '../constant/validations';
import { AuthService } from '../services/auth.service';
import { FormValidateService } from '../services/form-validate.service';
import { SnackBarService } from '../services/snack-bar.service';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  fullscreen: boolean;
  constructor( 
     private fb: FormBuilder, 
     private auth: AuthService,
     private router:Router ,
     private snackBar: SnackBarService,
     private formValidate: FormValidateService

     ) {
    this.registerForm = this.fb.group({
      company: ['', [Validators.required]],
      salutation: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      telephone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(validation.email)]],
    })
  }

  ngOnInit(): void {
  }



  registerSubmit() {
    this.fullscreen = true;
    if (this.registerForm.valid) {
      this.auth.registration(this.registerForm.value).subscribe((val: any) => {
      if(val.success){
        this.fullscreen = false;
        this.router.navigateByUrl('/login')
      }
      }, (err) => {
        this.fullscreen = false;
        this.snackBar.showSnackBar('Something went wrong', 'Dismiss', 3000, 'blue-snackbar')
        console.error("Error : " + JSON.stringify(err));
      }, () => {
        console.log('login req completed', 56);
      });
    }
    else{
      this.formValidate.validateAllFormFields(this.registerForm)
    }
  }
}
