import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { valueReplacerPipe } from '../pipes/value-replacer.pipe';

@Directive({
  selector: '[OnlyNumberwitDotComma]'
})
export class OnlyNumberWithOutDotDirective {
  private regex: RegExp = new RegExp(/^[0-9.-]*\,?\d{0,2}$/);
  constructor(private elRef: ElementRef,private replacer:valueReplacerPipe) { }
  @HostListener('input', ['$event']) onInputChange(event) {

    const initalValue = this.elRef.nativeElement.value.toString();
    this.elRef.nativeElement.value = initalValue.replace(/[^\d.,-]/g, '');
    if (initalValue.includes('-')){
      if (initalValue.lastIndexOf('-') == 0){
      } else {
        let v = initalValue.replace(/-/g, '');
        this.elRef.nativeElement.value = '-' + v;
      }
    }
    if ( initalValue !== this.elRef.nativeElement.value) {
      event.stopPropagation();
    }else{
      if(!this.elRef.nativeElement.value.match(this.regex)){
        this.elRef.nativeElement.value =  this.elRef.nativeElement.value.substring(0, this.elRef.nativeElement.value.length - 1)
      }
    }
  }
  }

