<div class="WH-loginpage-middleContent" *ngIf="forgetPassword">
    <div class="row align-items-center justify-content-center h-100">
        <div class="col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4">
            <div class="WH-loginForm WH-login">
                <h5 class="WH-loginTxt">Forgot Password Page</h5>
                <p>Please enter an email address. we will sent you an email with a temporary password that you can use to login and set a new password for your account.</p>
                <form class="form-group" [formGroup]="forgotPassForm">
                    <label for="email">Email:</label>
                    <input type="email" class="form-control rounded-0 h-40" formControlName="emailId" />
                    <div *ngIf="forgotPassForm?.get('emailId')?.invalid && (forgotPassForm?.get('emailId')?.dirty || forgotPassForm?.get('emailId')?.touched)" class="alert alert-danger">
                        <div *ngIf="forgotPassForm?.get('emailId')?.errors?.required">
                            {{errMsg}}
                        </div>
                        <div *ngIf="forgotPassForm?.get('emailId')?.errors?.pattern">Email must be a valid email address
                        </div>
                    </div>
                </form>
                <button type="submit" class="btn btn-primary w-100 border-0" (click)="forgotPassword()">SUBMIT</button>
                <div class="mt-3" *ngIf="serverError">
                    <div class="WH-errorMsgBox">
                        <ul class="WH-errorMsg">
                            <li style="padding: 0px;">{{backEndMsg}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-loader [fullscreen]="fullscreen"></app-loader>
</div>
<div class="WH-loginpage-middleContent" *ngIf="!forgetPassword">
    <div class="row align-items-center justify-content-center h-100">
        <div class="col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4">
            <div style="text-align: center;">
                <div class="WH-loginForm">
                    <h5 class="WH-loginTxt">Temporary Password</h5>
                    <p>Temporary password successfully sent to your registered email address. Please login with your temporary password</p>
                    <button type="submit" class="btn btn-primary w-100 border-0 mt-10" routerLink="/login">LOGIN</button>
                </div>
            </div>
        </div>
    </div>
</div>