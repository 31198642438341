
export const validation = {
    password : /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z0-9!@#$%^&*()~¥=_+}{":;'?/>.<,`\-\|\[\]]{6,50}$/,//Minimum six characters, at least one letter and one number;
    email:/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
 }


 export function generateTempPass(){
    return new Promise(async (reslove, reject) => {
        try {
            let j, x, i;
            let result:any = '';
            let letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
            let numbers = '0123456789';
                result += letters.charAt(Math.floor(Math.random() * letters.length));
            for (i = 0; i < 5; i++) {
                result += numbers.charAt(Math.floor(Math.random() * numbers.length));
            }
            result = result.split("");
            for (i = result.length - 1; i > 0; i--) {
                j = Math.floor(Math.random() * (i + 1));
                x = result[i];
                result[i] = result[j];
                result[j] = x;
            }
            result = result.join("");
            reslove(result) ;
        }
        catch (error) {
            reject(error)
        }
    });
  }


//   compare two objects
export function compareObjects(firstObj,secondObj) {
    return new Promise(async (resolve, reject) => {
        try {
            if(JSON.stringify(firstObj) === JSON.stringify(secondObj)){
                resolve(true)
            }else{
                resolve(false)
            }
        } catch (error) {
            reject(false)
        }
    })
}