<div>
    <div class="h-100 WH-sectionContent">
        <div class="WH-settingsPage WH-employeePage h-100" style="justify-content: center;display: flex;">
            <div class="WH-setingsPage-align">
                <div>
                    <!-- class="row d-flex justify-content-center" -->
                    <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="tab WH-tabs">
                            <mat-tab-group mat-align-tabs="center" [selectedIndex]="activeTab" (selectedIndexChange)="tabChanged($event)">
                                <!-- Account -->
                                <mat-tab label="Account" *ngIf="tokenAvailable">
                                    <form [formGroup]="basicInfoForm" (ngSubmit)="first()">
                                        <!-- class="WH-userDetails WH-primaryContact" -->
                                        <!-- <div class="row d-flex justify-content-center">
                                            <div class="col-12 col-sm-12 col-lg-3 ">
                                                <div class="WH-myAccount text-center">
                                                    <div class="WH-deleteAccountContent" *ngIf="!imageUnavailable">
                                                        <div class="WH-myAccount-edit">
                                                            <span class="material-icons WH-editIcon" (click)="deleteProfilePic(picId)">delete</span>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="!imageUnavailable" style="align-items: center;display: inline-grid;padding-top: 3px;margin-bottom: 5px !important;">
                                                        <img alt="profile pic" *ngIf="picId != ''" style="width:142px;height:142px;border-radius:50%;border: 1px solid rgba(0,0,0,.12);" [src]="picId | getGridfs | async">
                                                    </div>
                                                    <div class="name" style="margin-bottom: 0px !important;" *ngIf="imageUnavailable">
                                                        {{short_name}}
                                                    </div>
                                                    <div class="WH-editAccountContent">
                                                        <div class="WH-myAccount-edit">
                                                            <span class="material-icons WH-editIcon" (click)="editProfileImage()">create</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="container justify-content-center">
                                            <div class="row custom-wrapper">
                                                <div class="col-md-12">
                                                    <div class="group-gap">
                                                        <!-- Upload image -->
                                                        <div class="avatar-upload">
                                                            <div class="avatar-preview">
                                                                <img *ngIf="!imageUnavailable &&picId != ''" [src]="picId | getGridfs | async">
                                                                <div class="loader" *ngIf="!imageUnavailable && picId == ''">Loading...</div>
                                                                <div class="textVertical" *ngIf="imageUnavailable">{{short_name}}</div>
                                                            </div>
                                                            <div class="imgBtn">
                                                                <div class="WH-myAccount-edit">
                                                                    <span class="material-icons WH-editIcon" (click)="deleteProfilePic(picId)">delete</span>
                                                                </div>
                                                                <div class="WH-myAccount-edit">
                                                                    <span class="material-icons WH-editIcon" (click)="editProfileImage()">create</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div class="row d-flex justify-content-center">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="row WH-form-group-space pt-15">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div class="form-group WH-form2inRow">
                                                            <label for="email">Email</label>
                                                            <input type="text" formControlName="email" class="form-control rounded-0" autocomplete="new-password" disabled>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div class="form-group WH-form2inRow">
                                                            <label for="firstname">First Name</label>
                                                            <input type="text" class="form-control rounded-0" formControlName="firstName" />
                                                            <!-- firstName  error -->
                                                            <div *ngIf="basicInfoForm.get('firstName').invalid && (basicInfoForm.get('firstName').dirty || basicInfoForm.get('firstName').touched)" class="alert alert-danger">
                                                                <div *ngIf="basicInfoForm.get('firstName').errors.required">
                                                                    {{errMessage}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div class="form-group">
                                                            <label for="lastname">Last Name</label>
                                                            <input type="text" class="form-control rounded-0" formControlName="lastName" />
                                                            <!--lastName error -->
                                                            <div *ngIf="basicInfoForm.get('lastName').invalid && (basicInfoForm.get('lastName').dirty || basicInfoForm.get('lastName').touched)" class="alert alert-danger">
                                                                <div *ngIf="basicInfoForm.get('lastName').errors.required">
                                                                {{errMessage}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="WH-myAccount text-center d-none">
                                                    <img src="../../assets/my-profile.png" />
                                                    <div class="WH-editAccountContent">
                                                        <div class="WH-myAccount-edit">
                                                            <span class="material-icons WH-editIcon">create</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="WH-btnSpace">
                                                    <button class="btn btn-primary w-100 cursor-pointer">Update Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </mat-tab>



                                <mat-tab label="Password">
                                    <form [formGroup]="resetPasswordForm" (ngSubmit)="passwordData()" style="margin-top: 50px;width: 500px">
                                        <div class="row d-flex justify-content-center">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div class="row WH-form-group-space">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div class="form-group">
                                                            <label for="password">Email</label>
                                                            <div class="input-group">
                                                                <input type="text" class="form-control rounded-0" [value]="email" disabled>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div class="form-group">
                                                            <label for="password">Temporary/Current Password</label>
                                                            <div class="input-group">
                                                                <input [type]="passwordVisibility1 ? 'text' : 'password'" class="form-control rounded-0" formControlName="tempPassword">
                                                                <div class="input-group-append vertical">
                                                                    <span class="input-group-text ">
                                                                            <span class="material-icons WH-visibiltyIcon"
                                                                                (click)="passwordVisibility1 = !passwordVisibility1">
                                                                                {{passwordVisibility1 ? 'visibility' :
                                                                                'visibility_off'}}
                                                                            </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="resetPasswordForm.get('tempPassword').invalid && (resetPasswordForm.get('tempPassword').dirty || resetPasswordForm.get('tempPassword').touched)" class="alert alert-danger">
                                                                <div *ngIf="resetPasswordForm.get('tempPassword').errors.required">
                                                                    {{errMessage}}
                                                                </div>
                                                                <div *ngIf="resetPasswordForm.get('tempPassword').errors.minlength">
                                                                    Password must be at least 6 characters long.
                                                                </div>
                                                                <div *ngIf="resetPasswordForm.get('tempPassword').errors.pattern">
                                                                    Password contains at least one letter and one number.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div class="form-group">
                                                            <label for="password">New Password</label>
                                                            <div class="input-group">
                                                                <input [type]="passwordVisibility2 ? 'text' : 'password'" class="form-control rounded-0" formControlName="newPassword">
                                                                <div class="input-group-append vertical">
                                                                    <span class="input-group-text ">
                                                                            <span class="material-icons WH-visibiltyIcon"
                                                                                (click)="passwordVisibility2 = !passwordVisibility2">
                                                                                {{passwordVisibility2 ? 'visibility' :
                                                                                'visibility_off'}}
                                                                            </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="resetPasswordForm.get('newPassword').invalid && (resetPasswordForm.get('newPassword').dirty || resetPasswordForm.get('newPassword').touched)" class="alert alert-danger">
                                                                <div *ngIf="resetPasswordForm.get('newPassword').errors.required">
                                                                    {{errMessage}}
                                                                </div>
                                                                <div *ngIf="resetPasswordForm.get('newPassword').errors.minlength">
                                                                    Password must be at least 6 characters long.
                                                                </div>
                                                                <div *ngIf="resetPasswordForm.get('newPassword').errors.pattern">
                                                                    Password contains at least one letter and one number.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div class="form-group">
                                                            <label for="password">Confirm Password</label>
                                                            <div class="input-group">
                                                                <input [type]="passwordVisibility3 ? 'text' : 'password'" class="form-control rounded-0" formControlName="confirmPassword">
                                                                <div class="input-group-append vertical">
                                                                    <span class="input-group-text ">
                                                                            <span class="material-icons WH-visibiltyIcon"
                                                                                (click)="passwordVisibility3 = !passwordVisibility3">
                                                                                {{passwordVisibility3 ? 'visibility' :
                                                                                'visibility_off'}}
                                                                            </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="resetPasswordForm.get('confirmPassword').invalid && (resetPasswordForm.get('confirmPassword').dirty || resetPasswordForm.get('confirmPassword').touched)" class="alert alert-danger">
                                                                <div *ngIf="resetPasswordForm.get('confirmPassword').errors.required">
                                                                    {{errMessage}}
                                                                </div>
                                                                <div *ngIf="resetPasswordForm.get('confirmPassword').errors.minlength">
                                                                    Password must be at least 6 characters long.
                                                                </div>
                                                                <div *ngIf="resetPasswordForm.get('confirmPassword').errors.pattern">
                                                                    Password contains at least one letter and one number.
                                                                </div>
                                                                <div *ngIf="resetPasswordForm.get('confirmPassword').errors.mustmatch">
                                                                    New password and confirm password must match.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="WH-myAccount text-center d-none">
                                                    <img src="../../assets/my-profile.png" />
                                                    <div class="WH-editAccountContent">
                                                        <div class="WH-myAccount-edit">
                                                            <span class="material-icons WH-editIcon">create</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="WH-btnSpace">
                                                    <button class="btn btn-primary w-100 cursor-pointer" [disabled]="!resetPasswordForm.valid">Update
                                                            Now</button>
                                                </div>
                                            </div>
                                            <div class="WH-loginError mt-3" *ngIf="passwordError">
                                                <div class="WH-errorMsgBox">
                                                    <ul class="WH-errorMsg">
                                                        <li>{{errMsg}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-loader [fullscreen]="fullscreen"></app-loader>
</div>