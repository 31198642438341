import { validation } from './../constant/validations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { mustmatch } from '../constant/mustmatch';
import { CMSService } from '../services/cms.service';
import { SnackBarService } from '../services/snack-bar.service';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material/dialog';
import { ImageUploadComponent } from '../image-upload/image-upload.component';
import { compareObjects } from './../constant/validations';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { FormValidateService } from '../services/form-validate.service';


@Component({
  selector: 'app-cms-use-settings',
  templateUrl: './cms-use-settings.component.html',
  styleUrls: ['./cms-use-settings.component.css']
})
export class CmsUseSettingsComponent implements OnInit {
  email:string = '';
  passwordError: boolean = false;
  fullscreen: boolean;
  errMsg: string = '';
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  saveImage: boolean = false;
  short_name: string = '';
  fileToUpload: File;
  picId: string = '';
  passwordVisibility1: boolean = false;
  passwordVisibility2: boolean = false;
  passwordVisibility3: boolean = false;
  dynamicDetailsClass: boolean = false;
  tokenAvailable: boolean = false;
  imageUnavailable: boolean = false;
  step: string = "0";
  activeTab: any = 0;
  step1: boolean;
  step2: boolean;
  resetPasswordForm: FormGroup;
  basicInfoForm: FormGroup;
  errMessage;

  constructor(
    private _formBuilder: FormBuilder,
    public cms: CMSService,
    private snackBar: SnackBarService,
    public AuthService: AuthService,
    private router: Router,
    // private auth: AuthService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private formValidate:FormValidateService
  ) {

    this.activatedRoute.params.subscribe(params => {
      this.activeTab = params['id'] == 'password' ? 1 : 0;
      if (this.activeTab == 1) {
        // let tab = params['id'] == 0 ? "account" : 'password';
        this.router.navigate(['/CmsUseSettings', 'password']);
      } else {
        this.router.navigate(['/CmsUseSettings', "account"]);
      }

    });
    this.resetPasswordForm = this._formBuilder.group({
      tempPassword: ['', [Validators.required, Validators.minLength(6), Validators.pattern(validation.password)]],
      newPassword: ['', [Validators.required, Validators.minLength(6), Validators.pattern(validation.password)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6), Validators.pattern(validation.password)]],
    }, { validator: mustmatch('newPassword', 'confirmPassword') });

    this.basicInfoForm = this._formBuilder.group({
      email: [localStorage.getItem('email'), [Validators.required, Validators.pattern(validation.email)]],
      firstName: [localStorage.getItem('firstName'), [Validators.required]],
      lastName: [localStorage.getItem('lastName'), [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.errMessage=this.formValidate.errMessage()
    this.email = localStorage.getItem('email');
    const storageToken = localStorage.getItem('token');
    const profilePicId = localStorage.getItem('profilePicId');

    if (storageToken == '' || storageToken == undefined || storageToken == null) {
      this.tokenAvailable = false
    } else {
      this.tokenAvailable = true
    }

    if (profilePicId == '' || profilePicId == undefined || profilePicId == null) {
      const fName = localStorage.getItem('firstName') ? localStorage.getItem('firstName').charAt(0) : '';
      const lName = localStorage.getItem('lastName') ? localStorage.getItem('lastName').charAt(0) : '';
      this.imageUnavailable = true;
      this.short_name = `${fName}${lName}`;
    } else {
      setTimeout(() => {
        this.picId = localStorage.getItem('profilePicId')
        this.imageUnavailable = false
      }, 1500);
    }

  }
  secondBack() {
    this.step1 = true;
    this.step2 = false;
    this.dynamicDetailsClass = false;
  }

  async first() {
    if (this.basicInfoForm.valid) {
      let first = {
        email: localStorage.getItem('email'),
        firstName: localStorage.getItem('firstName'),
        lastName: localStorage.getItem('lastName')
      }
      if (await compareObjects(first, this.basicInfoForm.value)) {
        this.snackBar.showSnackBar('No changes detected', 'Dismiss', 3000, 'blue-snackbar')
      } else {
        let confirmation: any;
        confirmation = await this.editInformation(this.basicInfoForm.value, "basic");
        this.step1 = true;
        this.step2 = true;
        this.dynamicDetailsClass = true;
      }
    }
  }
  editInformation(data, key) {
    this.fullscreen = true;
    return this.cms.editCmsUserBasicInfo(data).subscribe(info => {
      this.fullscreen = false;
      if (info.success) {
        if (key == "basic") {
          localStorage.setItem("firstName", this.basicInfoForm.value.firstName)
          localStorage.setItem("lastName", this.basicInfoForm.value.lastName)
          let userInfo = {
            firstName: localStorage.getItem("firstName"),
            lastName: localStorage.getItem("lastName"),
            salutation: localStorage.getItem("salutation"),
            phNumber: localStorage.getItem("telephone"),
            email: localStorage.getItem("email"),
          }
          this.AuthService.userdetails.next(userInfo);
        } else {
          this.resetPasswordForm.reset()
        }
        this.snackBar.showSnackBar('Details are succesfully updated', 'Dismiss', 3000, 'blue-snackbar')
      } else {
        this.snackBar.showSnackBar(info.msg, 'Dismiss', 3000, 'blue-snackbar')
      }
    }, (err) => {
      this.fullscreen = false;
      this.snackBar.showSnackBar('Something went wrong', 'Dismiss', 3000, 'blue-snackbar')
    })
  }
  async passwordData() {
    if (this.tokenAvailable) {
      let confirmation: any;
      confirmation = await this.editInformation(this.resetPasswordForm.value, "secret");
    } else {
      const mail = localStorage.getItem('email');
      if (mail != '' || mail != undefined || mail != null) {
        let data = Object.assign(this.resetPasswordForm.value, { email: mail })
        this.fullscreen = true;
        this.AuthService.updatePassword(data).subscribe((val: any) => {
          this.fullscreen = false;
          if (val.success) {
            this.AuthService.clearLocalStorage();
            this.AuthService.setTokenlocalStorage(val);
            this.AuthService.checkToken()
            this.router.navigate(['/clientsView/account/clients']);
          } else {
            this.passwordError = true;
            this.errMsg = val.msg;
            setTimeout(() => {
              this.passwordError = false;
              this.errMsg = '';
            }, 10000);

          }
        }, (err) => {
          this.fullscreen = false;
          this.snackBar.showSnackBar('Something went wrong', 'Dismiss', 3000, 'blue-snackbar')
        })
      }
    }
  }
  tabChanged(e) {
    let tab = e == 0 ? 'account' : 'password';
    this.router.navigate(['/CmsUseSettings', tab]);
    this.resetPasswordForm.reset();
  }

  editProfileImage() {
    const dialog = this.dialog.open(ImageUploadComponent, {
      panelClass: 'my-full-screen-dialog',
      width: '40%',
      disableClose: true,
      // data: logoImgB64
    });
    dialog.afterClosed().subscribe((data) => {
      if (data.save) {
        this.fullscreen = true;
        this.fileToUpload = data.imageData;
        const formData = new FormData();
        formData.append('Image', this.fileToUpload);
        formData.append('picName', 'profilePic');
        formData.append('uId', localStorage.getItem('uId'));
        formData.append('email', localStorage.getItem('email'));
        this.fullscreen = true;
        this.AuthService.uploadProfilePic(formData).subscribe(data => {
          if (data.success) {
            this.fullscreen = false;
            this.imageUnavailable = false;
            this.picId = '';
            localStorage.setItem('profilePicId', data.data.profilePicId)
            this.AuthService.setProfileObs(data.data.profilePicId);
            this.fullscreen = false;
            setTimeout(() => {
              this.picId = data.data.profilePicId;
            }, 1500);

          }
        }, (err) => {
          this.fullscreen = false;
          this.snackBar.showSnackBar('Something went wrong', 'Dismiss', 3000, 'blue-snackbar')
        })

      } else {
        console.log('image not selected')
      }
    })
  }


  deleteProfilePic(picId) {
    const confirmationDialog = this.dialog.open(DeleteConfirmationComponent, {
      width: '35%',
      data: {
        title: "Sind Sie sicher, dass Sie dies löschen möchten?"
      }
    });
    confirmationDialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.fullscreen = true;
        this.AuthService.deleteProfilePic({ id: picId }).subscribe((data) => {
          if (data.success) {
            this.AuthService.setProfileObs('');
            localStorage.setItem("profilePicId", '');
            this.AuthService.setProfileObs('');
            const fName = localStorage.getItem('firstName') ? localStorage.getItem('firstName').charAt(0) : '';
            const lName = localStorage.getItem('lastName') ? localStorage.getItem('lastName').charAt(0) : '';
            this.imageUnavailable = true;
            this.short_name = `${fName}${lName}`;
            this.snackBar.showSnackBar('Profile pic successfully deleted', 'Dismiss', 3000, 'blue-snackbar');
            this.fullscreen = false;
          }
        }, (err) => {
          this.fullscreen = false;
          this.snackBar.showSnackBar('Something went wrong', 'Dismiss', 3000, 'blue-snackbar')
        })
      } else {
        console.log('modal dismissed')
      }
    });














  }

}
