import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CloseOpenedDialogsGuard implements CanActivateChild {
  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private dialog: MatDialog
) {}

canActivateChild(route: ActivatedRouteSnapshot): boolean {
    if (this.dialog.openDialogs.length > 0) {
        const currentUrlTree = this.router.createUrlTree([], route);
        const currentUrl = currentUrlTree.toString();
        this.location.go(currentUrl);

        this.dialog.closeAll();
        return false;
    } else {
        return true;
    }
}
  
}
