import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './services/auth.guard';
import { CloseOpenedDialogsGuard } from './services/close-opened-dialogs.guard';
import { CmsUseSettingsComponent } from './cms-use-settings/cms-use-settings.component';

const routes: Routes = [
 
  {
    path: 'login', component: LoginComponent,
  },
  {
    path: 'register', component: RegisterComponent,
  },

  {
    path: 'forgot', component: ForgotPasswordComponent,
  },
  {
    path: 'CmsUseSettings/:id', component: CmsUseSettingsComponent,
  },
  {
    path: "productPrices", canActivate: [AuthGuard], canActivateChild: [CloseOpenedDialogsGuard],
    loadChildren: () => import('./product-and-pricingpage/productPrice.module').then(m => m.ProductPriceModule)
  },
  {
    path: "clientsView", canActivate: [AuthGuard], canActivateChild: [CloseOpenedDialogsGuard],
    loadChildren: () => import('./client/client.module').then(m => m.ClientModule)
  },
  {
    path: "employeeView", canActivate: [AuthGuard], canActivateChild: [CloseOpenedDialogsGuard],
    loadChildren: () => import('./employees-view/employess-view.module').then(m => m.EmployeeModule)
  },
  {
    path: "ordersView", canActivate: [AuthGuard], canActivateChild: [CloseOpenedDialogsGuard],
    loadChildren: () => import('./orders-view/order-view.module').then(m => m.OrderViewModule)
  },
  {
    path: "storageView", canActivate: [AuthGuard], canActivateChild: [CloseOpenedDialogsGuard],
    loadChildren: () => import('./storage/storage-view.module').then(m => m.StorageViewModule)
  },
  {
    path: "settingsView", canActivate: [AuthGuard], canActivateChild: [CloseOpenedDialogsGuard],
    loadChildren: () => import('./settings-view/settings-view.module').then(m => m.SettingsViewModule)
  },

  {
    path: '', redirectTo: '/login', pathMatch: 'full',
  },
  { path: '**', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
