import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable } from '@angular/core';
import { LoaderComponent } from '../loader/loader.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  overlayRef: OverlayRef;
  componentFactory: ComponentFactory<LoaderComponent>;
  componentPortal: ComponentPortal<LoaderComponent>;
  componentRef: ComponentRef<LoaderComponent>;
  constructor(
    // private overlay: Overlay,
    // private componentFactoryResolver: ComponentFactoryResolver
  ) {
    // this.overlayRef = this.overlay.create(
    //   {
    //     hasBackdrop: true,
    //     backdropClass: 'cdk-overlay-backdrop-loader',
    //     positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
    //   }
    // );
    // this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(LoaderComponent);
    // this.componentPortal = new ComponentPortal(this.componentFactory.componentType);
    
  }


  show() {
    // this.overlayRef.attach<LoaderComponent>(this.componentPortal);
    // this.overlayRef.backdropClick().subscribe(() => {
    //   console.log('loader backdrop clicked');
    //   this.overlayRef.detach()
    // }
    // );
  }

  hide() {
    // this.overlayRef.detach();
  }
  
}
