import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { CmsUsersDeliverySettingsService } from '../services/cms-users-delivery-settings.service';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-client-limit',
  templateUrl: './client-limit.component.html',
  styleUrls: ['./client-limit.component.css']
})
export class ClientLimitComponent implements OnInit {
  beforeedit: boolean=false;
afterEdit:boolean=false;
  edivalue:boolean=false
  value: void;
  limit: any;
  showOrderValue = true;
  fullscreen: boolean;
  defaultData: boolean=true; 
  companydata: string;
  clientId: any;
  // limit: any;
  constructor(private router: Router,private cmsSettings:CmsUsersDeliverySettingsService,private client:ClientService) { }

  ngOnInit(){
    this.updateOrderLimit("first")
    this.showOrderValue = this.showOrderValue;
    this.companydata = localStorage.getItem('companyName');
    this.clientId = localStorage.getItem('clientId');
    
  }

  goBack() {
    this.router.navigate(['/clientsView/account/clients'])
  }
  
changeField(e) {
  this.limit = e.target.value;
}

updateOrderLimit(from) {
  let data={
    uId:this.router.url.split('/')[3],
  }
  if (from === 'edit' && this.showOrderValue) {
    this.showOrderValue = !this.showOrderValue;
    this.defaultData=false
    return
  }

  if(from === 'edit') {
    typeof(this.limit) == 'number'? data['limit'] = this.limit: data['limit']=parseInt(this.limit.replace(/\./g,''));
  }
  this.fullscreen=true;
  this.cmsSettings.clientOrderLimit(data).subscribe(data => {
    if (data.success) {
      this.fullscreen = false;
      if (from ==='edit') {
        this.showOrderValue = !this.showOrderValue;
        
      }
      this.limit = data.data.clientOrderLimit || data.data.limit
      
    }
  })
}

spect() {
  let data = {
    id: this.router.url.split('/')[3],
  }

  this.client.spectateUser(data).subscribe((val) => {
   let a = window.open(`https://wahrspectate.julius66.net/spectate/${val.token}/${val.data[0].uId}`)
  //  a.addEventListener('close' )
  })
}
}
