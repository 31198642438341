import { CmsSocketService } from './../services/cms-socket.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { validation } from '../constant/validations';
import { AuthService } from '../services/auth.service';
import { FormValidateService } from '../services/form-validate.service';
import { SnackBarService } from '../services/snack-bar.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  passwordVisibility: boolean = false;
  userNotFound: boolean = false;
  fullscreen: boolean;
  backendErrorMsg: string = "";
  errMessage;
  constructor(
    private fb: FormBuilder,
    private auth: AuthService, private router: Router,
    private formValidate: FormValidateService,
    private activatedRoute: ActivatedRoute,
    private socket: CmsSocketService,
    private snack: SnackBarService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(validation.email)]],
      password: ['', [Validators.required, ]]
    });
  }

  ngOnInit(): void {
    this.errMessage=this.formValidate.errMessage();
    if (localStorage.getItem('token')) {
      this.router.navigate(['/clientsView/account/clients']);
    } else {
      this.auth.logout();
    }
  }

  loginSubmit() {
    // this.errMessage=this.formValidate.errMessage();
    if (this.loginForm.valid) {
      this.fullscreen = true;
      this.auth.loginUser(this.loginForm.value).subscribe((login: tokenInfo) => {
        this.fullscreen = false;
        if (login.success) {
           this.auth.clearLocalStorage();
          this.auth.setTokenlocalStorage(login);
          this.router.navigate(['/clientsView']);
          // this.socket.connectToSocket()
        } else {
          if (login.msg == "Passowrd match") {
            this.router.navigate(['/CmsUseSettings/password']);
            localStorage.setItem('email', login.data[0].email)
          } else {
            this.userNotFound = true;
            this.backendErrorMsg = login.msg;
          }
        }
      }, (err) => {
        this.fullscreen = false;
        this.snack.showSnackBar('Something went wrong', 'Dismiss', 3000, 'blue-snackbar')
      }, () => {
        // console.log('login req completed', 56);
      });
    } else {
      this.formValidate.validateAllFormFields(this.loginForm)
      console.log("login form not valid", 83);
    }
  }

}
export interface tokenInfo {
  success: boolean,
  data: any[],
  codeNode: number,
  token: any,
  msg: any,
}



          // if(login.msg == "Password not match") {
          //   this.userNotFound = true;
          //   this.backendErrorMsg =  "Password Wrong";
          // } else if(login.msg == "redirectTheUserTochangePasswordPage") {
          //   this.snack.showSnackBar('', 'Dismiss', 3000, 'blue-snackbar')
          //   this.router.navigate(['/forgetPass']);

          // }else{
          //   this.userNotFound = true;
          //   this.backendErrorMsg = "user not registered with this mail";
          // }

    // this.activatedRoute.queryParams.subscribe((params:{ redirect_uri: string, response_type: string, state: string }) => {
    //   console.log(JSON.stringify(params)  , 24);
    // });
    // console.error(err);
        // this.userNotFound = true;
        // this.backendErrorMsg = "something went wrong";