import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inputReplacer'
})
export class InputReplacerPipe implements PipeTransform {

  transform(value: any,decimal:boolean, negative: boolean) {
    if (!negative && typeof(value) == 'string'){
      value = value.replace(/-/g, '');   
    }
    
    var regex = new RegExp("^[0-9,.]|-");
    if (value && regex.test(value)) { 
      value = value.toString();
      if(decimal){
        let mainValue = value.replace(".",",")
        // let commaValue:any = value.split(',')[1] == undefined ? 0 : value.split(',')[1];
        // var number:any = mainValue+'.'+ commaValue;
        
        // mainValue = parseInt(mainValue);
        return mainValue.toLocaleString('de-DE');
      }else{
        value = value.split(',')[0].split('.').join('');
        value = parseInt(value);
        return value.toLocaleString('de-DE');
      }
    } else {
      return 0
    }
  }

}
