export const config = {
   api: 'http://localhost:7200/api/',
    // api:'https://wahrportalapi.julius66.net/api/',
    liters:"€/100l",
    // socket: 'http://localhost:7200',
    socket:'https://wahrportalapi.julius66.net'

}





// CHANGE TO NUMBER FORMAT
export function changeToNumber(value) {
    if(value && value != null){
        value = value.toString();
        value = value.split('.').join('');
        value = value.replace(',','.');
       return value;
    }else{
        return 0;
    }
}
