<div [ngClass]="{'importDiv': previewImage === false}">
    <div class="text-center col-md-12" *ngIf="!previewImage">
        <img class="downloadIcon" src="../../assets/downloadIcon.svg" />
        <div>Drop file here!</div>
    </div>
    <input type="file" #fileInput class="uploadInput" accept=".png, .jpg, .jpeg" (dragenter)="dropzone = true"
        (dragleave)="dropzone = false" (change)="fileChangeEvent($event);fileChange_logo($event)" />
</div>
<div class="row" style="margin-top: 5%;" *ngIf="previewImage">
    <div class="text-center col-md-12">
        <h5>Crop Image</h5>
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
            [resizeToWidth]="256" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
</div>
<div class="text-center">
    <button mat-stroked-button class="btn btn-default WH-btnWidth" style="background-color: #A9A9A9;" (click)="fileInput.click()">Choose file</button>
</div>
<br />
<button mat-stroked-button class="btn btn-default WH-btnWidth" style="float:left"
    (click)=savePicture(false)>Cancel</button>
<button mat-stroked-button style="float:right" class="btn btn-primary WH-btnWidth"
    (click)=savePicture(true)>Save</button>