import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InputReplacerPipe } from '../pipes/input-replacer.pipe';
import { FormValidateService } from '../services/form-validate.service';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.css']
})
export class DeleteConfirmationComponent implements OnInit {
  @ViewChild('toggleButton') toggleButton: ElementRef;
  showSingle: boolean;
  Button: boolean = false;
  toggleChange: boolean;
  customPrice: any;
  resetapi: any;
  togglePopup: any;
  deleteEmployee: boolean = false;
  showDropdown: boolean;
  supportForm: FormGroup;
  employeeData: any;
  initialData: any;
  errMessage: any;
  // Button:true;
  constructor(
    private inputReplacer: InputReplacerPipe,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private formValidate: FormValidateService,
    public dialogRef: MatDialogRef<DeleteConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.Button = data.Button;
    if (data?.deleteEmployee) {
      this.deleteEmployee = true;
      this.employeeData = data.allEmployees;
      this.initialData = data.allEmployees;
      this.supportForm = this.fb.group({
        standard: ['', Validators.required]
      })
      this.renderer.listen('window', 'click', (e: Event) => {        
        let clickOnOuside = e.target !== this.toggleButton.nativeElement && !e.target.toString().includes('Label') && !e.target.toString().includes('Input') && !e.target.toString().includes('HTMLLIElement');
        if (clickOnOuside && this.showDropdown) {
          this.showDropdown = false;
        }
      })
    }
    if ('showSingle' in data) {
      this.toggleChange = false;
      this.showSingle = data.showSingle;
      this.data = data
    } else if (data.toggle === "customToggle") {
      this.toggleChange = true;
      this.customPrice = data.price;
    }
   else{
    this.togglePopup = data.type === "togglePopup";
   }
    this.resetapi = data.reset;
  }

  ngOnInit(): void {
    this.errMessage=this.formValidate.errMessage();
  }

  onClickDropdown() {
    this.showDropdown = !this.showDropdown
  }

  onSearch(e) {
    let val = e.target.value;    
    this.employeeData = this.initialData.filter(each => (each.firstName1.includes(val) || each.lastName1.includes(val)));
  }

  deleteUser(){
    this.supportForm.markAllAsTouched()
    if (this.supportForm.valid){
      this.dialogRef.close({ user: this.supportForm.get('standard').value })
    }
  }

  close() {
    this.dialogRef.close(false)
  }

  closeDialog(close) {
    if (close){
      this.dialogRef.close({ save: false })
    } else {
      this.dialogRef.close({ price: this.customPrice, save: true })
    }
  }

  valueChange(value) {
    this.customPrice = this.inputReplacer.transform(value, true, true);
  }
  maintanance(value){
    this.dialogRef.close({accept:value})
  }
}
