import { CmsSocketService } from './../services/cms-socket.service';
import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from "@angular/router";
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  unsubscribe$: Subject<boolean> = new Subject();
  logedIn: boolean = false;
  isLoggedIn: Observable<boolean>;
  navbarOpen: boolean = false;
  card = false;
  userDetails: any;
  short_name: string;
  fullName: any;
  userMail: any;
  imageUnavailable: boolean = false;
  picId: string = '';
  constructor(
    private authService: AuthService,
    private Router: Router,
    private _eref: ElementRef,
    public socketService: CmsSocketService,
    public dialog: MatDialog,
  ) {
    this.isLoggedIn = this.authService.isLoginSubject;
    window.addEventListener('resize', function(event) {
      document.getElementById("mySidenav").style.width = "0";
      document.getElementById("mySidenav").style.display= "none";
      document.getElementById("SideNavBackdrop").style.width = "0";
      document.getElementById("SideNavBackdrop").style.height = "0";
      document.getElementById("SideNavBackdrop").style.display= "none";
    }, true);
  }

  @HostListener('window:click', ['$event'])
  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.card = false;
    }
  }

  ngOnInit(): void {
    this.authService.getProfileObs().pipe(takeUntil(this.unsubscribe$)).subscribe(profile => {
      if (profile == '' || profile == undefined || profile == null) {
        const fName = localStorage.getItem('firstName') ? localStorage.getItem('firstName').charAt(0) : '';
        const lName = localStorage.getItem('lastName') ? localStorage.getItem('lastName').charAt(0) : '';
        this.imageUnavailable = true;
        this.short_name = `${fName}${lName}`;
      } else {
        this.picId = profile;
        this.imageUnavailable = false;
      }
    });
    const profilePicId = localStorage.getItem('profilePicId');
    if (profilePicId == '' || profilePicId == undefined || profilePicId == null) {
      const fName = localStorage.getItem('firstName') ? localStorage.getItem('firstName').charAt(0) : '';
      const lName = localStorage.getItem('lastName') ? localStorage.getItem('lastName').charAt(0) : '';
      this.imageUnavailable = true;
      this.short_name = `${fName}${lName}`;
    } else {
      this.picId = localStorage.getItem('profilePicId');
      this.imageUnavailable = false
    }

    this.authService.userdetails.subscribe((userInfo: any) => {
      this.userDetails = userInfo;
      // const nachname = this.userDetails.firstName ? this.userDetails.firstName.charAt(0) : '';
      // const vorname = this.userDetails.lastName ? this.userDetails.lastName.charAt(0) : '';
      // this.short_name = `${nachname}${vorname}`;
      this.fullName = this.userDetails.firstName + ' ' + this.userDetails.lastName;
      this.userMail = this.userDetails.email;
    });

  }

  logOutUser() {
    this.socketService.disconnect();
    this.authService.logout();
    // this.card = false;
    this.dialog.closeAll()
    document.getElementById("mySidenav").style.display= "none";
    document.getElementById("SideNavBackdrop").style.display= "none";
    // const confirmationDialog = this.dialog.open(DeleteConfirmationComponent, {
    //   width: '35%',
    //   panelClass: 'deleteModal',
    //   data: {
    //     title: "Sind Sie sicher, dass Sie dies löschen möchten?"
    //   }
    // });
    // confirmationDialog.afterClosed().subscribe((result: boolean) => {
    //   if (result) {
    //     this.socketService.disconnect()
    //     this.authService.logout();
    //   } else {
    //     console.log('dialog dismissed')
    //   }
    // });
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
  closeCard(url) {
    this.card = false;
    if (url == 'storageView') {
      if (window.location.pathname.includes(url)) {
        this.Router.navigate([window.location.pathname]);
      }
      // this.Router.navigateByUrl('/', {skipLocationChange: true}).then(() =>this.Router.navigate([url]));
    }
  }
  openNav() {
    document.getElementById("mySidenav").style.width = "50%";
    document.getElementById("mySidenav").style.display= "block";
    document.getElementById("mySidenav").style.background= "block";
    document.getElementById("SideNavBackdrop").style.width = "100%";
    document.getElementById("SideNavBackdrop").style.height = "100%";
    document.getElementById("SideNavBackdrop").style.display= "flex";
    }

    closeSideNav(){
        document.getElementById("mySidenav").style.width = "0";
        document.getElementById("mySidenav").style.display= "none";
        document.getElementById("SideNavBackdrop").style.width = "0";
        document.getElementById("SideNavBackdrop").style.height = "0";
        document.getElementById("SideNavBackdrop").style.display= "none";
    }
}
