import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {

  }
  canActivate(): boolean {
    if (this.auth.checkToken()) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  // canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  //   if (this.auth.checkToken()) {
  //     return true;
  //   }
  //   // navigate to login page
  //   this.router.navigate(['/login']);
  //   return false;
  // }

}
