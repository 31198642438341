import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-show-subscribed-clients',
  templateUrl: './show-subscribed-clients.component.html',
  styleUrls: ['./show-subscribed-clients.component.css']
})
export class ShowSubscribedClientsComponent implements OnInit {
  show: boolean = false;
  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<ShowSubscribedClientsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  

  }

  ngOnInit(): void {
  }
}
