<div class="form-group ">
  <textarea style="width: 100%;" id="exampleFormControlTextarea6" rows="10" cols="50" placeholder="Write something here..." [(ngModel)]="name"></textarea>
  <!-- <span class="textarea" role="textbox" contenteditable>{{name}}</span> -->
  <div class="mt-1">
  <button  class="btn btn-default WH-btnWidth" style="float:left" (click)="cancle()">Abbrechen</button>
    <button  style="float:right" class="btn btn-primary WH-btnWidth" (click)="save()">Speichern</button>
</div>
</div>
<!-- <app-legal-text></app-legal-text> -->

