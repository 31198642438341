<!-- <div class="WH-sectionTabs-content"> -->

    <!-- <div class="WH-breadcrumb cursor-pointer">
        <span class="material-icons WH-backArrow " (click)="goBack()">keyboard_backspace</span>
        <span>Kunden/Limits </span>
    </div> -->
    <!-- <div class="WH-subHeading">Tageslimit
        <input type="number"  class="inputclass"  (keyup)="enter($event)" *ngIf="beforeedit" name="value" [value]="value || '0' | inputReplacer:false: true"/>
        <span *ngIf="!beforeedit" style="margin-left:70px ;">{{value || '0' | inputReplacer:false: true}}</span>
        <div class ="iconAlignments">
        <span class="material-icons WH-edit cursor-pointer" (click)="edit($event)" *ngIf="!beforeedit">edit</span>
        <span class="material-icons cursor-pointer WH-edit" *ngIf="beforeedit  " (click)="done($event)">done</span>
        </div>
    </div> -->
    
    

<!-- </div> -->
<div class="WH-settingsPage-tabs_Content">
    <!-- <div class="WH-breadcrumb">
        Kunden/Limits
    </div> -->
    <div class="WH-breadcrumb cursor-pointer">
        <span class="material-icons WH-backArrow " (click)="goBack()">keyboard_backspace</span>
        <span style="padding-top:5px;">Kunden/Limits </span>
    </div>
    <div class="d-flex flex-row" style="align-items: center;">
        <div class="WH-subHeading">{{companydata}} ({{clientId}})</div>
        <button type="button" class="btn btn-primary admin-button" (click)="spect()">Admin-Ansicht</button>
    </div>

    <div style="padding-top: 10px;">
        <div class="row">
            <div class="col-1.5 spacing"><span class="text">Tageslimit</span></div>
            <div><input class="spacing h-30" [disabled]="showOrderValue" [value]="limit | inputReplacer:false: false"
                    (keyup)="changeField($event)" [ngClass]="showOrderValue ? 'inputOrder' : 'inputOrder2'" />&nbsp;€</div>
            <div class="col-1 spacing" (click)="updateOrderLimit('edit');" style="height: 32px;width: 70px;">
                <div *ngIf="showOrderValue " class="material-icons WH-edit cursor-pointer editSpace">edit</div>
                <div *ngIf="!showOrderValue" class="material-icons cursor-pointer WH-edit editSpace">
                    done</div>
            </div>
        </div>
    </div>

    <app-loader [fullscreen]="fullscreen"></app-loader>

</div>
