import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CmsSocketService {

  constructor(private socket: Socket) { }
  getStoragesData = () => {
    return new Observable(observer => {
      this.socket.on('key', data => {
        observer.next(data);
      })
    });
  }
  connectToSocket() {
    this.socket.connect();
  }
  disconnect() {
    this.socket.disconnect();
  }
}
