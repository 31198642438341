<nav class="navbar navbar-expand-lg navbar-light WH-header WH-navbar sticky-top" *ngIf="(isLoggedIn | async)" style="z-index: 999 !important;">
    <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleNavbar()">
        <span class="navbar-toggler-icon"></span>
    </button> -->
    <mat-icon class="menu"(click)="openNav()">menu</mat-icon>
    <div class="WH-logo">
        <img src="assets/wahr-logo.png" alt="logo" />
    </div>
    <div class="collapse navbar-collapse" id="navbarNav" [ngClass]="{ 'show': navbarOpen }" (click)="closeSideNav()" >
        <ul class="navbar-nav ml-auto align-items-center">
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/clientsView" (click)=closeCard()>
                    <span>Kunden</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/ordersView" (click)=closeCard()>
                    <span>Bestellungen</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/productPrices" (click)=closeCard()>
                    <span>Produkte & Preise</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/storageView" (click)="closeCard('storageView')">
                    <span>Lager</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/employeeView" (click)=closeCard()>
                    <span>Nutzerverwaltung</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/settingsView" (click)=closeCard()>
                    <span>Einstellungen</span>
                </a>
            </li>
            <li class="nav-item cursor-pointer" routerLinkActive="active">
                <div class="container justify-content-center">
                    <div class="row custom-wrapper">
                        <div class="col-md-12">
                            <div class="group-gap">
                                <!-- Upload image -->
                                <div class="avatar-upload">
                                    <div class="avatar-preview" (click)="card= !card">
                                        <img *ngIf="!imageUnavailable &&picId != ''" [src]="picId | getGridfs | async">
                                        <div class="textVertical" *ngIf="imageUnavailable">{{short_name}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="card" class="pmcard d-none d-lg-block">
                    <div class="modal-content z-depth-1 pcard">
                        <div class="modal-body" class="arrow_box" style="padding: 0.8rem;">
                            <div class="testingarea"></div>
                            <div class="row pt-3">
                                <div class="col-12" style="align-items: center;display: flex;">
                                    <div class="container justify-content-center">
                                        <div class="row custom-wrapper">
                                            <div class="col-md-12">
                                                <div class="group-gap">
                                                    <!-- Upload image -->
                                                    <div class="avatar-upload">
                                                        <div class="avatar-preview">
                                                            <img *ngIf="!imageUnavailable && picId != ''"
                                                                [src]="picId | getGridfs | async"
                                                                (click)="card = !card;hello()">
                                                            <div class="textVertical" *ngIf="imageUnavailable"
                                                                (click)="card = !card">{{short_name}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="text-align: center;padding-left: 10px;">
                                        <div>
                                            <h5 style="color: #e30613;">{{fullName}}</h5>
                                        </div>
                                        <div id="a" style="margin-left: 3px;">{{userMail}}</div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row pt-3" routerLink="/CmsUseSettings/0" (click)="card = false"
                                style="cursor: pointer;outline: 0;">
                                <mat-icon>settings</mat-icon>
                                <span class="plabel">Settings</span>
                            </div>
                            <div class="row pt-3 pb-3" (click)="logOutUser()" style="cursor: pointer;">
                                <mat-icon>power_settings_new</mat-icon>
                                <span class="plabel" data-dismiss="modal">Logout</span>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</nav>

<div id="SideNavBackdrop">
<div id="mySidenav" class="sidenav">
    <mat-icon style="padding-left:8px; font-size: 40px;color: grey;cursor: pointer;" (click)="closeSideNav()">close</mat-icon>
        <div>
            <div>
            <div class="avatar-upload d-flex flex-row" Style="position: relative;margin: 20px 0px 0px 15px;align-items: center;">
            <div class="avatar-preview">
                <img style="width: 100%; height: 100%;" *ngIf="!imageUnavailable && picId != ''"
                    [src]="picId | getGridfs | async"
                    (click)="card = !card;hello()">
                <div class="textVertical" *ngIf="imageUnavailable"
                    (click)="card = !card">{{short_name}}</div>
            </div>
            <div style="padding: 0px 0px 0px 10px">
                <div>
                    <h5 style="color: #e30613;">{{fullName}}</h5>
                </div>
                <div id="a">{{userMail}}</div>
            </div>
        </div>
        <ul style=" margin-top: 20px;">
         <li class="nav-item" routerLinkActive="active" (click)="closeSideNav()">
                <a class="nav-link" routerLink="/clientsView">
                    <span class="ActiveTab">Kunden</span>
                </a>
            </li>
                       <li class="nav-item" routerLinkActive="active" (click)="closeSideNav()">
                <a class="nav-link" routerLink="/ordersView">
                    <span class="ActiveTab">Bestellungen</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active" (click)="closeSideNav()">
                <a class="nav-link" routerLink="/productPrices">
                    <span class="ActiveTab">Produkte & Preise</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active" (click)="closeSideNav()">
                <a class="nav-link" routerLink="/storageView">
                    <span class="ActiveTab">Lager</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active" (click)="closeSideNav()">
                <a class="nav-link" routerLink="/employeeView" >
                    <span class="ActiveTab">Nutzerverwaltung</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active" (click)="closeSideNav()">
                <a class="nav-link" routerLink="/settingsView">
                    <span class="ActiveTab">Einstellungen</span>
                </a>
            </li>
            <li class="nav-item" routerLinkActive="active" (click)="closeSideNav()">
                <a class="nav-link" routerLink="/CmsUseSettings/account">
                    <span class="ActiveTab" >Settings</span>
                </a>
            </li>
            <li style="margin-top: 18px;" class="sidenavcontents">
                <div class="row pt-3 pb-3" (click)="logOutUser()" style="cursor: pointer;">
                    <mat-icon style="padding-left: 12px;">power_settings_new</mat-icon>
                    <span  style="font-size:16px !important; margin-left: 22px;">Logout</span>
                  </div>
            </li>
           </ul>
    </div>
    </div>
</div>
<div style="width:50%;" (click)="closeSideNav()">
</div>
</div>
<header class="WH-header bg-white WH-shadow" *ngIf="!(isLoggedIn | async)">
    <div class="WH-logo">
        <img src="../../assets/wahr-logo.png" alt="logo" />
    </div>
</header>