import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { config } from '../constant/config';
import { EnvService } from '../env.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  protected requests = new BehaviorSubject(0);
  requests$ = this.requests.asObservable().pipe(distinctUntilChanged());
  apiUrl:any;
  constructor(private http:HttpClient,private env: EnvService) {
    this.apiUrl=this.env.apiUrl;
   }


  getRequestedClients(sortOptions):Observable<any>{
    return this.http.post(this.apiUrl + "clients/getClientRequests",sortOptions);
  }

  getClientAccountsData(sortingOptions):Observable<any>{
    return this.http.post(this.apiUrl + "clients/getClientAccounts",sortingOptions);
  }

  updateRequest(id):Observable<any>{
    return this.http.post(this.apiUrl + "clients/updateRequest",id);
  }
  getCompanyDataById(id):Observable<any>{
    return this.http.post(this.apiUrl + "clients/getCompanyDataById",id);
  }

  spectateUser(id):Observable<any>{
    return this.http.post(this.apiUrl + "clients/SpectateUser",id);
  }

  editGenralInfo(value):Observable<any>{
    return this.http.post(this.apiUrl + "clients/editGeneralInfo",value);
  }
  updatePrimaryContact(value):Observable<any>{
    return this.http.post(this.apiUrl + "clients/editPrimaryContact",value)
  }
  generateFrontendUserPassword(value):Observable<any>{
     return this.http.post(this.apiUrl + "clients/generateFrontendUserPassword",value)
    }

    getProductsWithCities(data):Observable<any>{
      return this.http.post(this.apiUrl + "clients/getProductsWithCities",data)
    }
    francogetProductsWithCities():Observable<any>{
      return this.http.get(this.apiUrl + "clients/getfrancoProductsWithCities")
    }
    addPickUp(data):Observable<any>{
      return this.http.post(this.apiUrl + "clients/addPickUp",data)
    }
   getPickupData(data):Observable<any>{
     return this.http.post(this.apiUrl + "clients/getPickupData",data)
    }
    getFrancoData(data):Observable<any>{
      return this.http.post(this.apiUrl + "clients/getFrancoData", data)
    }
    changePickupStatus(data):Observable<any>{
      return this.http.post(this.apiUrl + "clients/changePickupMainStatus",data)
    }
    insertDeliveryLocation(val):Observable<any>{
      return this.http.post(this.apiUrl + "clients/addDeliveryStation",val)
    }
    getFrancoDeliveryPoints(data):Observable<any>{
      return this.http.post(this.apiUrl + "clients/getFrancoDeliveryPoints",data)
    }
    deleteFrancDeliveryStation(id):Observable<any>{
      return this.http.post(this.apiUrl + "clients/deleteFrancoDeliveryPoint",id)
    }
    changeFrancoStatus(val):Observable<any>{
      return this.http.post(this.apiUrl + "clients/francoStatusChange",val)
    }
    updateProductsInfo(data):Observable<any>{
      return this.http.post(this.apiUrl + "clients/updateProductInfo",data)
    }
    updateProductQualityInfo(data):Observable<any>{
      return this.http.post(this.apiUrl + "clients/updateProductQualityInfo",data);
    }
    newProductInsertion(data):Observable<any>{
      return this.http.post(this.apiUrl + "clients/newProductInsert",data)
    }
    updateCityInfo(data):Observable<any> {
      return this.http.post(this.apiUrl + "clients/updateCityInfo",data)
    }
    changeFrancoProductMargin(data):Observable<any>{
     return this.http.post(this.apiUrl + "clients/changeFrancoProductMargin",data)
    }
    changeFrancoQualityMargin(data):Observable<any>{
     return this.http.post(this.apiUrl + "clients/changeFrancoQualityMargin",data)
    }
    truckMarginValueChange(val):Observable<any>{
      return this.http.post(this.apiUrl + "clients/francoTruckMarginChange",val)
    }
    updataFrancoSelectedCity(data):Observable<any>{
      return this.http.post(this.apiUrl + "clients/updataFrancoSelectedCity",data)
    }
    insertClientNotAddedQuality(data):Observable<any>{
      return this.http.post(this.apiUrl + "clients/insertClientNotAddedQuality",data)
    };
    insertClientNotAddedPickupQuality(data):Observable<any>{
      return this.http.post(this.apiUrl + "clients/insertPikupNotAddedQuality",data)
    };
    deleteUserPickupPoint(data):Observable<any>{
      return this.http.post(this.apiUrl + "clients/deleteUserPickUpPoint",data)
    };
    updateFrancoProductCollapse(data):Observable<any>{
      return this.http.post(this.apiUrl + "clients/updateFrancoProductCollapse",data)
    };
    deleteUserAccount(data):Observable<any>{
      return this.http.post(this.apiUrl + "clients/delteUserEntireInfo",data)
    };
    getSettings():Observable<any>{
      return this.http.get(this.apiUrl + "clients/cmsUserPickupSettings")
    }
    getClientOrders(data):Observable<any>{
      return this.http.post(this.apiUrl + "clients/getClientOrders",data)
    }
    allRequests(value){
      this.requests.next(value);
    }
  }

