import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { GetGridfsPipe } from '../pipes/get-gridfs.pipe';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements OnInit {
  dropzone:boolean = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  logoname = '';
  imagePreview: any;
  Logo_limit_exceed: boolean;
  unterdata: any;
  file: File;
  imageId = '';
  imageName = '';
  base64: any = false;
  previewImage:boolean = false;
  filechangedata: File;
  constructor(
    public dialogRef: MatDialogRef<ImageUploadComponent>,
    private getGridFs:GetGridfsPipe,
    private auth:AuthService
  ) { }

  async ngOnInit() {
    const profilePicId = localStorage.getItem('profilePicId');
    if (profilePicId == '' || profilePicId == undefined || profilePicId == null) {
      this.previewImage = false
    } else {
      this.previewImage = true;
      const pic = await this.getGridFs.transform(localStorage.getItem('profilePicId'));
      this.imageName = this.auth.pictureName;
      let imageFile = this.dataURLtoFile(pic, this.imageName);
      let list = new DataTransfer();
      list.items.add(imageFile)
      this.imageChangedEvent = { target: list };

    }

  }


    fileChangeEvent(event: any): void {
      this.imageName = event.target.files[0].name;
      this.previewImage = event.isTrusted ? true:false
      this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
    }
    fileChange_logo(event) {
      let fileList: FileList = event.target.files;
      if ((fileList.length == 1) && (fileList[0].size <= 1000000)) {
        this.logoname = fileList[0].name;
        const logoname = fileList[0].name;
        var reader = new FileReader();
        reader.onload = (e: any) => {
          this.imagePreview = e.target.result;
        }
        reader.readAsDataURL(event.target.files[0])
        var _URL = window.URL;
        var file, img;
        img = new Image();
        img.onload = (e) => {
          const defaultImageHeight = 150;
          if (img.height >= defaultImageHeight) {
            let percentage = (img.height - defaultImageHeight) / img.height;
            let newHeight = (img.height - (percentage * img.height));
            let newWidth = (img.width - (percentage * img.width));
   
            const elem: any = document.createElement('canvas');
            elem.width = newWidth;
            elem.height = newHeight;
            const ctx = elem.getContext('2d');
            ctx.drawImage(img, 0, 0, newWidth, newHeight);
            ctx.canvas.toBlob((blob) => {
              let file = new File([blob], logoname, {
                type: 'image/jpeg',
                lastModified: Date.now(),
              });
              setImageData(file);
            });
          } else {
            this.filechangedata = fileList[0];
          }
        }
        const setImageData = (file: File) => {
          this.filechangedata = file
        }
        img.src = _URL.createObjectURL(event.target.files[0]);
      } else {
        this.Logo_limit_exceed = true;
        this.logoname = '';
        setTimeout(() => {
          this.Logo_limit_exceed = false;
        }, 4500);
      }
   
    }
    savePicture(save){
      if(save){
        let imageFile = this.dataURLtoFile(this.croppedImage, this.imageName);
        this.dialogRef.close({
          save:true,
          imageData:imageFile
        });
      }else{
        this.dialogRef.close({
          save:false
        });
      }
    }
    
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }
}
