import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommomModuleRoutingModule } from './common-module-routing.module';
import { CommaDotReplacedPipe } from '../pipes/comma-dot-replaced.pipe';
import { OnlyNumberWithOutDotDirective } from "../directives/only-number-with-out-dot.directive";
import { OnlyNumberWithoutDotComma } from "../directives/only-number.directive";
import { NgbModule, NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { UnitsReplacePipe } from '../pipes/units-replace.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from '../loader/loader.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { valueReplacerPipe } from '../pipes/value-replacer.pipe';
import { InputReplacerPipe } from '../pipes/input-replacer.pipe';

@NgModule({
  declarations: [
    CommaDotReplacedPipe,
    OnlyNumberWithOutDotDirective,
    OnlyNumberWithoutDotComma,
    UnitsReplacePipe,
    LoaderComponent,
    valueReplacerPipe,
    InputReplacerPipe
  ],
  imports: [
    CommonModule,
    CommomModuleRoutingModule,
    NgbPaginationModule, 
    NgbAlertModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule
  ],
  exports: [
    CommaDotReplacedPipe,
    UnitsReplacePipe,
    OnlyNumberWithOutDotDirective,
    OnlyNumberWithoutDotComma,
    NgbPaginationModule, 
    NgbAlertModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderComponent,
    MatTooltipModule,
    valueReplacerPipe,
    InputReplacerPipe

  ],
  providers:[
    UnitsReplacePipe,
    valueReplacerPipe,
    InputReplacerPipe
  ]
})
export class CommomModuleModule { }
