import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.css']
})
export class TextareaComponent implements OnInit {
  legaltext: any;
  name: string;
  constructor(public dialogRef: MatDialogRef<TextareaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {
    this.name = this.data.text
  }
  ngOnInit(): void {
  }
  cancle() {
    this.dialogRef.close(false);
  }
  save() {
    let data = {
      text: this.name
    }
    this.dialogRef.close(data);

  }
}
