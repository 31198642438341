import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private _snackBar: MatSnackBar) {

  }

  showSnackBar(message: string, action: string, duration: number, cssClassName: string) {
    this._snackBar.open(message, action, {
      duration: duration,
      panelClass: [cssClassName]
    });
  }



}
