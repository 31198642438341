<div *ngIf="!toggleChange && !togglePopup && !deleteEmployee">
  <div *ngIf="!resetapi">
    <h2 class="matHeadTitle" *ngIf="!Button">Löschen</h2>
    <h2 class="matHeadTitle" *ngIf="Button">Löschen</h2>
  </div>
  <br />
  <div class="matContent">{{data?.title}}</div>
  <div mat-dialog-actions style="width: 100%; " class="WH-btns h-100" *ngIf="!resetapi">
    <div class="WH-btn-p-r" style="width: 40%; display: contents"> <button mat-button [mat-dialog-close]="false"
        class="btn btn-default WH-btnWidth" *ngIf="!showSingle && !Button">Abbrechen</button></div>

    <div class="WH-btn-p-r" style="width: 40%; display: contents"> <button mat-button [mat-dialog-close]="false"
        class="btn btn-default WH-btnWidth" *ngIf="!showSingle && Button">Abbrechen</button></div>
    <div style="width: 10%;">&nbsp;</div>
    <div class="WH-btn-p-l" style="width: 40%; display: contents"> <button mat-button [mat-dialog-close]="true"
        cdkFocusInitial class="btn btn-primary WH-btnWidth" *ngIf="!Button">Löschen</button> </div>
    <div class="WH-btn-p-l" style="width: 40%; display: contents"> <button mat-button [mat-dialog-close]="true"
        cdkFocusInitial class="btn btn-primary WH-btnWidth" *ngIf="Button">Löschen</button> </div>
  </div>
  <div mat-dialog-actions style="width: 100%; " class="WH-btns h-100" *ngIf="resetapi">
    <div class="WH-btn-p-r" style="width: 40%; display: contents"> <button mat-button [mat-dialog-close]="false"
        class="btn btn-default WH-btnWidth">Abbrechen</button></div>
    <div style="width: 10%;">&nbsp;</div>
    <div class="WH-btn-p-l" style="width: 40%; display: contents"> <button mat-button [mat-dialog-close]="true"
        cdkFocusInitial class="btn btn-primary WH-btnWidth">Reset</button> </div>
  </div>
</div>
<div *ngIf="toggleChange && !deleteEmployee">
  <h1 class="matHeadTitle mb-3">Hinweis</h1>
  <p class="matContent text-left mb-3">Sie wechseln von der Preisautomatik zum statischen Preismodell. Bitte prüfen Sie
    noch einmal den aktuell eingestellten Preis, bevor Ihr Pricing umgestellt wird:</p>
  <div class="d-flex justify-content-center mb-4 align-items-center">
    <label style="font-size: 16px; text-transform: capitalize; margin-bottom: 4px;">{{data.product}}:</label>
    <input OnlyNumberwitDotComma [ngModel]="customPrice | valueReplacer:true" (ngModelChange)="valueChange($event)"
      class="form-control ml-3 custom-input" autocomplete="off" />
  </div>
  <div class="d-flex justify-content-between">
    <button cdkFocusInitial class="btn btn-default WH-btnWidth" (click)="closeDialog(true)">Abbrechen</button>
    <button cdkFocusInitial class="btn btn-primary WH-btnWidth" (click)="closeDialog(false)">Speichern</button>
  </div>
</div>
<div *ngIf="togglePopup && !deleteEmployee">
  <h1 class="matHeadTitle mb-3">Warnung</h1>
  <p class="matContent text-left mb-3">Sie setzen das Portal in den Wartungsmodus. Dadurch werden alle Nutzer des
    Portals ausgeloggt. Der Login sowie die Registrierung ist damit deaktiviert. Sind Sie sicher, dass Sie den
    Wartungsmodus aktivieren wollen?</p>
  <div class="d-flex justify-content-between">
    <button cdkFocusInitial class="btn btn-default WH-btnWidth" (click)="maintanance(false)">Abbrechen</button>
    <button cdkFocusInitial class="btn btn-primary WH-btnWidth" (click)="maintanance(true)">Bestätigen</button>
  </div>
</div>
<div *ngIf="deleteEmployee">
  <h1 class="matHeadTitle mb-3">Bestätigung</h1>
  <p class="matContent text-center mb-2">Sind Sie sicher, dass Sie dies löschen wollen?</p>
  <p class="matContent text-left mb-2">Der Mitarbeiter ist als Ansprechpartner bei einem oder mehr Kunden hinterlegt.
    Bevor dieser gelöscht werden kann, wählen Sie einen Mitarbeiter, der als neuer Ansprechpartner für diese Kunden
    eingesetzt wird:</p>
  <form [formGroup]="supportForm" class="d-flex flex-column align-items-center mb-3">
    <div>
      <div #toggleButton class="selectDropdown" (click)="onClickDropdown()">{{supportForm?.get('standard')?.value? supportForm?.get('standard')?.value?.firstName + ' ' + supportForm?.get('standard')?.value?.lastName: 'Auswählen'}}</div>
      <div *ngIf="(supportForm?.get('standard')?.invalid && supportForm?.get('standard')?.touched)"
        class="alert alert-danger">
        {{errMessage}}
      </div>
    </div>
    <ul *ngIf="showDropdown" [ngStyle]="(supportForm?.get('standard')?.invalid && supportForm?.get('standard')?.touched) && {'margin-top': '-27px'}">
      <li style="border-bottom: 1px solid #ddd;">
        <input type="search" placeholder="Suche..." (keyup)="onSearch($event)">
      </li>
      <li *ngFor="let employee of employeeData">
        <input class="test1" type="radio" [id]="employee?._id" formControlName="standard" [value]="employee">
        <label [for]="employee?._id">{{employee?.firstName + ' ' + employee?.lastName}}</label>
      </li>
    </ul>
  </form>
  <div class="d-flex justify-content-between">
    <button cdkFocusInitial class="btn btn-default WH-btnWidth" (click)="close()">Abbrechen</button>
    <button cdkFocusInitial class="btn btn-primary WH-btnWidth" (click)="deleteUser()">Löschen</button>
  </div>
</div>