import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { AuthService } from "./services/auth.service";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'wahrCMS';
  constructor(private auth: AuthService, private Router: Router, private location: Location) {
    this.auth.authenticationState$.subscribe(data => {
      if (!data) {
        let route = '/login' || '/register';
        this.Router.navigate([route])
      } else {
        if (this.location.path() == "" || this.location.path() == undefined) {
          this.Router.navigate(['clientsView']);
        } else {
          this.Router.events
            .pipe(
              filter(e => e instanceof NavigationEnd)
            ).subscribe((navEnd: NavigationEnd) => {
              // console.log(navEnd.urlAfterRedirects);
            });
        }

      }
    })

    // if(this.auth.checkToken()) {
    // this.Router.navigate(['clientsView']);
    // this.event$=location.onUrlChange((val) => {
    //   this.urlPaste=location.path()
    // })
    // console.log(location.path());
    // if(this.urlPaste =="" || this.urlPaste==undefined){
    //   this.Router.navigate(['clientsView']);
    // }else{
    //   this.Router.navigate([this.urlPaste]);
    // }
    //   } else {
    // let route = 'register' || 'login'
    //  this.Router.navigate([route])
    //  }
    
  }
}
