import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
    private auth: AuthService
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authService = this.injector.get(AuthService);
    const authReq = req.clone(
      {
        setHeaders: {
          Authorization: `${authService.getToken()}`,
          headers: 'Content-Type: application/json', 'Access-Control-Allow-Origin': '*'
        }
      }
    );

    return next.handle(authReq).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else if (error.status == 401) {
          // Invalid Authorization
          this.auth.logout();
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
      }),
      tap((ele:any) => {
        if (ele instanceof HttpResponse) {
          if (ele.body.code == 401 || ele.body.code === 403 || ele.body.code === 405) {
            this.auth.logout();
          } else {
            console.log("valid request");
          }
        }
      }),
    );
    // return next.handle(authReq).catch(err => this.handleError(err));
  }
  // private handleError(err: HttpErrorResponse): Observable<any> {
    // handle your auth error or rethrow
    // console.log(err, 32);

    // return null;
    // if (err.status === 401 || err.status === 403) {
    //   //navigate /delete cookies or whatever
    //   this.auth.logout();
    //   localStorage.clear();
    //   this.router.navigateByUrl('/login');
    //   // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
    //   return of(err.message); // or EMPTY may be appropriate here
    // }
    // return throwError(err);
  // }










  // intercept(req, next) {
  //   let authService = this.injector.get(AuthService);
  //   let tokenizedReq = req.clone({
  //     setHeaders: {
  //       Authorization: `${authService.getToken()}`,
  //       headers: 'Content-Type: application/json', 'Access-Control-Allow-Origin': '*'
  //     }
  //   });

  //   return next.handle(tokenizedReq).pipe(
  //     retry(0),
  //     catchError((error: HttpErrorResponse) => {
  //       let errorMessage = '';
  //       if (error.error instanceof ErrorEvent) {
  //         // client-side error
  //         errorMessage = `Error: ${error.error.message}`;
  //       } else {
  //         // server-side error
  //         errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  //       }
  //       return throwError(errorMessage);
  //     }),
  //     tap(ele => {
  //       if (ele instanceof HttpResponse) {
  //         if (ele.body && ele.body.msg == "Invalid Authorization!" || ele.body.code == 401 || ele.body.msg=="Premium expired!") {
  //           this.auth.logout();
  //           localStorage.clear();
  //           this.router.navigateByUrl('/login');
  //         }
  //       }
  //     }),
  //   );
  // }
}
