import { config } from './../constant/config';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '../env.service';

@Injectable({
  providedIn: 'root'
})
export class CmsUsersDeliverySettingsService {
  apiUrl: any;
  constructor(public http: HttpClient, private env: EnvService) {
    this.apiUrl = this.env.apiUrl;
  }
  deliveryPickupSettingsUpdate(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/pickupSettingChanges', data);
  }
  legalSettingsUpdate(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/legalSettingChanges', data);
  }
  getlegaldata(data = {}): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/getlegaldata', data);
  }

  getTruckdata(): Observable<any> {
    return this.http.get(this.apiUrl + 'cmsSettings/getTruckdata');
  }
  updateTruckdata(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/updateTruckdata', data);
  }
  deliveryFrancoSettingsUpdate(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/francoSettingChanges', data);
  }
  getDleiverySettingsData(): Observable<any> {
    return this.http.get(this.apiUrl + 'cmsSettings/getDeliverySettings');
  }
  clientOrderLimit(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/clientOrderLimit',data);
  }

  deliveryFrancoSettingsPartialUpdate(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/deliveryFrancoSettingsPartialUpdate', data);
  }
  deletePartialInfo(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/deletePartialInfo', data);
  }
  mixproduct(): Observable<any> {
    return this.http.get(this.apiUrl + 'cmsSettings/products');
  }
  addproductmix(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/addproductmix', data);
  }
  getmixedproductes(): Observable<any> {
    return this.http.get(this.apiUrl + 'cmsSettings/getmixedproductes');
  }
  deleteMix(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/deleteMix', data);
  }
  editMix(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/editMix', data);
  }
  minMax(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/minMax', data);
  }
  qualitysmixToggle(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/qualitysmixToggle', data);
  }
  createApi(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/createApiKey',data);
  }
  getApiKey(): Observable<any> {
    return this.http.get(this.apiUrl + 'cmsSettings/getApiKey');
  }
  deleteKey(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/deleteKey', data);
  }
  getMaintenanceData(data): Observable<any> {
    return this.http.get(this.apiUrl + 'cmsSettings/getlegaldata', data);
  }

  edittimer(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/editTimer',data);
  }
  getTimes(): Observable<any> {
    return this.http.get(this.apiUrl + 'cmsSettings/getTimes');
  }
  SettimeSlot(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/SettimeSlot',data);
  }
  deleteslot(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/deleteSlot', data); // new created backend not created
  }
  editslot(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/editslot', data); // new created backend not created
  }
  editUserSupport(data):Observable<any>{
    return this.http.post(this.apiUrl + "cmsSettings/editUserSupport",data)
  }
  getSupportData(): Observable<any> {
    return this.http.get(this.apiUrl + 'cmsSettings/getSupport');
  }
  editSupportData(data): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/editSupport', data);
  }

  getMaintainersData(): Observable<any> {
    return this.http.get(this.apiUrl + 'cmsSettings/getmaintaince');
  }
  updateMaintainceData(data:any): Observable<any> {
    return this.http.post(this.apiUrl + 'cmsSettings/updateMaintainceData', data);
  }
}
